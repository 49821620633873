/** @jsxRuntime classic */
/** @jsx jsx */
import { Box, Flex, Title } from '@bottlebooks/gatsby-theme-base';
import { jsx } from 'theme-ui';
import ExploreNavigation from './ExploreNavigation';

export default function ExploreWinesSwitcher({ children }) {
  return (
    <Flex direction="column" sx={{ alignItems: 'center' }}>
      <Title sx={{ marginY: 3 }}>Explore Wines by</Title>
      <ExploreNavigation
        sx={{
          marginY: 3,
          width: ['100%', null, 'initial'],
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      />
      <Box sx={{ textAlign: 'center', marginTop: 1, marginBottom: 3 }}>
        {children}
      </Box>
      <Box
        sx={{
          border: 1,
          borderColor: 'borderSecondary',
          width: '100%',
          marginBottom: 3,
        }}
      ></Box>
    </Flex>
  );
}
