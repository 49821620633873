/** @jsxRuntime classic */
/** @jsx jsx */
import { Button, Flex } from '@bottlebooks/gatsby-theme-base';
import { useMatch } from '@reach/router';
import { jsx } from 'theme-ui';

export default function ExploreNavigation({ ...rest }) {
  return (
    <Flex gap="2" {...rest}>
      <ExploreButton to="/explore/">Producer</ExploreButton>
      <ExploreButton to="/explore/by-importer">Importer</ExploreButton>
      <ExploreButton to="/explore/by-variety">Variety</ExploreButton>
      <ExploreButton to="/explore/new-releases">New for 2021</ExploreButton>
      <ExploreButton to="/explore/seeking-representation">
        Seeking representation
      </ExploreButton>
    </Flex>
  );
}

function ExploreButton({ children, to, ...rest }) {
  const match = useMatch(to);
  const isPartiallyActive = Boolean(match);
  return (
    <Button
      variant={isPartiallyActive ? 'primary' : 'outline'}
      to={to}
      partiallyActive
      sx={{
        transition: 'none',
        marginY: 1,
        width: ['45%', null, 'initial'],
        textAlign: 'center',
      }}
      {...rest}
    >
      {children}
    </Button>
  );
}
