/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import { Box, Container } from '@bottlebooks/gatsby-theme-base';
import { jsx } from 'theme-ui';
import SiteSearch from '@bottlebooks/gatsby-theme-event/src/components/SiteSearch/SiteSearch';

export default function SiteSearchSection({
  locale,
  //   ...rest
}) {
  return (
    <Container>
      <Box
        sx={{
          display: ['none', 'none', 'inherit'],
          transform: 'translateY(-50%)',
          position: 'relative',
          zIndex: 'modal',
        }}
      >
        <SiteSearch
          variant="largest"
          locale={locale}
          placeholder={`Search for producer, wine or region…`}
        />
      </Box>
    </Container>
  );
}
